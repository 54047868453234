// import React, { Suspense } from 'react';
// import { Navigate, Route, Routes } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { LayoutTypes } from '../constants/layout';
// import { RootState } from '../redux/store';
// import DefaultLayout from '../layouts/Default';
// import VerticalLayout from '../layouts/Vertical';
// import DetachedLayout from '../layouts/Detached';
// import HorizontalLayout from '../layouts/Horizontal/';
// import TwoColumnLayout from '../layouts/TwoColumn/';
// import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
// import Root from './Root';
// import { APICore } from '../helpers/api/apiCore';

// interface IRoutesProps {}

// const AllRoutes = (props: IRoutesProps) => {
//   const { layout } = useSelector((state: RootState) => ({
//     layout: state.Layout,
//   }));

//   const getLayout = () => {
//     switch (layout.layoutType) {
//       case LayoutTypes.LAYOUT_HORIZONTAL:
//         return HorizontalLayout;
//       case LayoutTypes.LAYOUT_DETACHED:
//         return DetachedLayout;
//       case LayoutTypes.LAYOUT_VERTICAL:
//         return VerticalLayout;
//       default:
//         return TwoColumnLayout;
//     }
//   };

//   const Layout = getLayout();
//   const api = new APICore();
//   const isAuthenticated = api.isUserAuthenticated();

//   return (
//     <Suspense fallback={<div>Loading...</div>}>
//       <Routes>
//         <Route path="/" element={<Root />} />
//         {publicProtectedFlattenRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={
//               <DefaultLayout {...props} layout={layout}>
//                 {route.element}
//               </DefaultLayout>
//             }
//             key={idx}
//           />
//         ))}
//         {authProtectedFlattenRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={
//               !isAuthenticated ? (
//                 <Navigate to="/auth/login" state={{ next: route.path }} />
//               ) : (
//                 <Layout {...props}>{route.element}</Layout>
//               )
//             }
//             key={idx}
//           />
//         ))}
//       </Routes>
//     </Suspense>
//   );
// };

// export default AllRoutes;


import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LayoutTypes } from '../constants/layout';
import { RootState } from '../redux/store';
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import TwoColumnLayout from '../layouts/TwoColumn/';
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import Root from './Root';
import { isAuthenticated } from './auth'; 

interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        return HorizontalLayout;
      case LayoutTypes.LAYOUT_DETACHED:
        return DetachedLayout;
      case LayoutTypes.LAYOUT_VERTICAL:
        return VerticalLayout;
      default:
        return TwoColumnLayout;
    }
  };

  const Layout = getLayout();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Root />} />
        {publicProtectedFlattenRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <DefaultLayout {...props} layout={layout}>
                {route.element}
              </DefaultLayout>
            }
            key={idx}
          />
        ))}
        {authProtectedFlattenRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              !isAuthenticated() ? (
                <Navigate to="/auth/login" state={{ next: route.path }} />
              ) : (
                <Layout {...props}>{route.element}</Layout>
              )
            }
            key={idx}
          />
        ))}
      </Routes>
    </Suspense>
  );
};

export default AllRoutes;

