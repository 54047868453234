// // src/services/api.ts

// const BASE_URL = 'http://localhost:8000';  // Replace with your FastAPI server URL

// export const loginUser = async (username: string, password: string) => {
//   const response = await fetch(`${BASE_URL}/auth/token`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: new URLSearchParams({
//       'username': username,
//       'password': password,
//     }),
//   });
  
//   if (!response.ok) {
//     const errorData = await response.json();
//     throw new Error(errorData.detail || 'Failed to login');
//   }
  
//   return response.json();
// };
// src/services/api.ts

// import { BASE_URL } from "../pages/apps/constants"; // Replace with your FastAPI server URL

// export const loginUser = async (username: string, password: string) => {
//   const response = await fetch(`${BASE_URL}/auth/token`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: new URLSearchParams({
//       'username': username,
//       'password': password,
//     }),
//   });

//   if (!response.ok) {
//     const errorData = await response.json();
//     throw new Error(errorData.detail || 'Failed to login');
//   }

//   const user = await response.json();

//   // Store token in localStorage
//   localStorage.setItem('token', user.access_token);

//   return user; // Ensure your backend returns a user object with an access_token
// };

// export const logoutUser = async () => {
//   localStorage.removeItem('token');
// };


// src/services/api.ts
import { BASE_URL } from "../pages/apps/constants"; // Replace with your FastAPI server URL

export const loginUser = async (username: string, password: string) => {
  const response = await fetch(`${BASE_URL}auth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      'username': username,
      'password': password,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Failed to login');
  }

  const user = await response.json();

  // Store token in localStorage
  localStorage.setItem('token', user.access_token);

  // Fetch user details with the token
  const userDetails = await fetchUserDetails(user.access_token);

  return userDetails; // Return user details along with the token
};

const fetchUserDetails = async (token: string) => {
  const response = await fetch(`${BASE_URL}auth/user/me`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user details");
  }

  const userDetails = await response.json();
  
  // Store user details in localStorage
  localStorage.setItem('user', JSON.stringify(userDetails));
  
  return userDetails;
};

export const logoutUser = async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};
