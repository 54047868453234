// // src/redux/auth/saga.ts

// import { all, fork, put, takeEvery, call } from "redux-saga/effects";
// import { SagaIterator } from "@redux-saga/core";
// import { loginUser } from '../../services/api';  // Import your fetch function for login
// import { authApiResponseSuccess, authApiResponseError } from "./actions";
// import { AuthActionTypes } from "./constants";

// function* login({ payload: { username, password } }: any): SagaIterator {
//   try {
//     const response = yield call(loginUser, username, password);
//     const user = response;
    
//     // Store token in localStorage
//     localStorage.setItem('token', user.access_token);

//     // Dispatch success action to update Redux state
//     yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
//   } catch (error: any) {
//     yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
//   }
// }

// // Other sagas like logout, signup, forgotPassword remain the same

// export function* watchLoginUser() {
//   yield takeEvery(AuthActionTypes.LOGIN_USER, login);
// }

// // Other watch functions remain the same

// function* authSaga() {
//   yield all([
//     fork(watchLoginUser),
//     // Include other forked sagas here
//   ]);
// }

// export default authSaga;
// src/redux/auth/saga.ts




// import { all, fork, put, takeEvery, call } from "redux-saga/effects";
// import { SagaIterator } from "@redux-saga/core";
// import { loginUser } from '../../services/api';
// import { authApiResponseSuccess, authApiResponseError } from "./actions";
// import { AuthActionTypes } from "./constants";

// function* login({ payload: { username, password, navigate } }: any): SagaIterator {
//   try {
//     const response = yield call(loginUser, username, password);
//     const user = response;

//     // Store token in localStorage
//     localStorage.setItem('token', user.access_token);

//     // Dispatch success action to update Redux state
//     yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));

//     // Redirect to the dashboard
//     navigate('/dashboard-1');
//   } catch (error: any) {
//     yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error.message));
//   }
// }

// // Other sagas like logout, signup, forgotPassword remain unchanged

// export function* watchLoginUser() {
//   yield takeEvery(AuthActionTypes.LOGIN_USER, login);
// }

// // Other watch functions remain unchanged

// function* authSaga() {
//   yield all([
//     fork(watchLoginUser),
//     // Include other forked sagas here
//   ]);
// }

// export default authSaga;

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { loginUser , logoutUser} from '../../services/api';
import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";

function* login({ payload: { username, password, navigate } }: any): SagaIterator {
  try {
    const response = yield call(loginUser, username, password);
    const user = response;

    // Store token in localStorage
    // localStorage.setItem('token', user.access_token);

    // Dispatch success action to update Redux state
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));

    // Redirect to the dashboard
    navigate('/apps/profile');
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error.message));
  }
}
function* logout(): SagaIterator {
  try {
    yield call(logoutUser);

    // Optionally, you can dispatch a success action or any other actions needed
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error.message));
  }
}
// Other sagas like logout, signup, forgotPassword remain unchanged

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}
export function* watchLogoutUser() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}
// Other watch functions remain unchanged

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    // Include other forked sagas here
  ]);
  
}

export default authSaga;
