import React, { Suspense }  from "react";
import { BrowserRouter as Router, Navigate,Routes, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from './Root';
const Loading = () => <div>Loading...</div>;
// lazy load all the views

// auth


// auth2
const Login = React.lazy(() => import("../pages/auth2/Login2"));
const Logout = React.lazy(() => import("../pages/auth2/Logout2"));
const Register2 = React.lazy(() => import("../pages/auth2/Register2"));
const Confirm2 = React.lazy(() => import("../pages/auth2/Confirm2"));
const ForgetPassword2 = React.lazy(
  () => import("../pages/auth2/ForgetPassword2")
);
const LockScreen2 = React.lazy(() => import("../pages/auth2/LockScreen2"));
const SignInSignUp2 = React.lazy(() => import("../pages/auth2/SignInSignUp2"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// dashboard
const Dashboard1 = React.lazy(() => import("../pages/dashboard/Dashboard1/"));
const Dashboard2 = React.lazy(() => import("../pages/dashboard/Dashboard2/"));
const Dashboard3 = React.lazy(() => import("../pages/dashboard/Dashboard3/"));
const Dashboard4 = React.lazy(() => import("../pages/dashboard/Dashboard4/"));

// apps
const CompaniesApp = React.lazy(() => import("../pages/apps/Company/"));
const aggregatedSalarysApp = React.lazy(() => import("../pages/apps/AggregatedSalary/"));
const SalariesByMonthApp = React.lazy(() => import("../pages/apps/SalariesByMonth/"));
const CompanyDetails = React.lazy(() => import("../pages/apps/CompnayDetails/"));

const aggregatedSalaryDetails = React.lazy(() => import("../pages/apps/AggregatedSalaryDetails/"));
const Profile = React.lazy(() => import("../pages/apps/Profile/"));
const BranchDetails = React.lazy(() => import("../pages/apps/BranchDetails/"));
const SalariesApp = React.lazy(() => import("../pages/apps/UserDetails/"));
const SalarySlipApp = React.lazy(() => import("../pages/apps/SalarySlip/"));

const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Projects = React.lazy(() => import("../pages/apps/Projects/"));
const ProjectDetail = React.lazy(
  () => import("../pages/apps/Projects/Detail/")
);
const ProjectForm = React.lazy(
  () => import("../pages/apps/Projects/ProjectForm")
);
// - chat
// const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));
// - ecommece pages
// const EcommerceDashboard = React.lazy(
//   () => import("../pages/apps/Ecommerce/Dashboard/")
// );
// const EcommerceProducts = React.lazy(
//   () => import("../pages/apps/Ecommerce/Products")
// );
// const ProductDetails = React.lazy(
//   () => import("../pages/apps/Ecommerce/ProductDetails")
// );
// const ProductEdit = React.lazy(
//   () => import("../pages/apps/Ecommerce/ProductEdit")
// );
// const Customers = React.lazy(() => import("../pages/apps/Ecommerce/Customers"));
// const Orders = React.lazy(() => import("../pages/apps/Ecommerce/Orders"));
// const OrderDetails = React.lazy(
//   () => import("../pages/apps/Ecommerce/OrderDetails")
// );
// const Sellers = React.lazy(() => import("../pages/apps/Ecommerce/Sellers"));
// const Cart = React.lazy(() => import("../pages/apps/Ecommerce/Cart"));
// const Checkout = React.lazy(() => import("../pages/apps/Ecommerce/Checkout"));
// - crm pages

// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));
const EmailCompose = React.lazy(() => import("../pages/apps/Email/Compose"));
// - social
const SocialFeed = React.lazy(() => import("../pages/apps/SocialFeed/"));
// - Card
const Card = React.lazy(() => import("../pages/apps/card"));
// - tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const TaskDetails = React.lazy(() => import("../pages/apps/Tasks/Details"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
// -contacts
// const ContactsList = React.lazy(() => import("../pages/apps/Contacts/List/"));
// const ContactsProfile = React.lazy(
//   () => import("../pages/apps/Contacts/Profile/")
// );
// -tickets
const TicketsList = React.lazy(() => import("../pages/apps/Tickets/List/"));
const TicketsDetails = React.lazy(
  () => import("../pages/apps/Tickets/Details/")
);
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Starter = React.lazy(() => import("../pages/other/Starter"));
const Timeline = React.lazy(() => import("../pages/other/Timeline"));
const Sitemap = React.lazy(() => import("../pages/other/Sitemap/"));
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Two = React.lazy(() => import("../pages/error/Error404Two"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Error500Two = React.lazy(() => import("../pages/error/Error500Two"));
// - other
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const FAQ = React.lazy(() => import("../pages/other/FAQ"));
const SearchResults = React.lazy(() => import("../pages/other/SearchResults/"));
const Upcoming = React.lazy(() => import("../pages/other/Upcoming"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));
const Gallery = React.lazy(() => import("../pages/other/Gallery/"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));

// uikit
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Avatars = React.lazy(() => import("../pages/uikit/Avatars"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Portlets = React.lazy(() => import("../pages/uikit/Portlets"));
const TabsAccordions = React.lazy(
  () => import("../pages/uikit/TabsAccordions")
);
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Notifications = React.lazy(() => import("../pages/uikit/Notifications"));
const Offcanvases = React.lazy(() => import("../pages/uikit/Offcanvas"));
const Placeholders = React.lazy(() => import("../pages/uikit/Placeholders"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const Images = React.lazy(() => import("../pages/uikit/Images"));
const Carousels = React.lazy(() => import("../pages/uikit/Carousel"));
const ListGroups = React.lazy(() => import("../pages/uikit/ListGroups"));
const EmbedVideo = React.lazy(() => import("../pages/uikit/EmbedVideo"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const Ribbons = React.lazy(() => import("../pages/uikit/Ribbons"));
const TooltipsPopovers = React.lazy(
  () => import("../pages/uikit/TooltipsPopovers")
);
const GeneralUI = React.lazy(() => import("../pages/uikit/GeneralUI"));
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));
const NestableList = React.lazy(() => import("../pages/uikit/NestableList"));
const DragDrop = React.lazy(() => import("../pages/uikit/DragDrop"));
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSliders"));
const Animation = React.lazy(() => import("../pages/uikit/Animation"));
const TourPage = React.lazy(() => import("../pages/uikit/TourPage"));
const SweetAlerts = React.lazy(() => import("../pages/uikit/SweetAlerts"));
const LoadingButtons = React.lazy(
  () => import("../pages/uikit/LoadingButtons")
);

// widgets
const Widgets = React.lazy(() => import("../pages/uikit/Widgets"));

// icons
const TwoToneIcons = React.lazy(() => import("../pages/icons/TwoToneIcons/"));
const FeatherIcons = React.lazy(() => import("../pages/icons/FeatherIcons/"));
const Dripicons = React.lazy(() => import("../pages/icons/Dripicons/"));
const MDIIcons = React.lazy(() => import("../pages/icons/MDIIcons/"));
const FontAwesomeIcons = React.lazy(
  () => import("../pages/icons/FontAwesomeIcons/")
);
const ThemifyIcons = React.lazy(() => import("../pages/icons/ThemifyIcons/"));
const SimpleLineIcons = React.lazy(
  () => import("../pages/icons/SimpleLineIcons/")
);
const WeatherIcons = React.lazy(() => import("../pages/icons/WeatherIcons/"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// charts
const ApexChart = React.lazy(() => import("../pages/charts/Apex"));
const ChartJs = React.lazy(() => import("../pages/charts/ChartJs"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}


const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    element: () => <Root />,
    route: Route,
};

// dashboards
// const dashboardRoutes: RoutesProps = {
//   path: "/dashboard",
//   name: "Dashboards",
//   icon: "airplay",
//   header: "Navigation",
//   children: [
//     {
//       path: "/",
//       name: "Root",
//       element: <Navigate to="/dashboard-1" />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/dashboard-1",
//       name: "Dashboard 1",
//       element: <Dashboard1 />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/dashboard-2",
//       name: "Dashboard 2",
//       element: <Dashboard2 />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/dashboard-3",
//       name: "Dashboard 3",
//       element: <Dashboard3 />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/dashboard-4",
//       name: "Dashboard 4",
//       element: <Dashboard4 />,
//       route: PrivateRoute,
//     },
//   ],
// };
const companiesAppRoutes = {
  path: "/apps/companies",
  name: "Companies",
  route: PrivateRoute,

  icon: "activity",
  element: <PrivateRoute component={CompaniesApp} roles={["admin"]} />,
};
const aggregatedSalarysAppRoutes = {
  path: "/apps/aggregatedSalary",
  name: "Aggregated Salary",
  route: PrivateRoute,

  icon: "activity",
  element: <PrivateRoute component={aggregatedSalarysApp} roles={["admin","manager"]} />,
};
const SalariesByMonthAppRoutes = {
  path: "/apps/SalariesByMonth",
  name: "SalariesByMonth",
  route: PrivateRoute,

  icon: "activity",
  element: <PrivateRoute component={SalariesByMonthApp} roles={["admin","manager"]} />,
};

const CompanyDetailsRoutes = {
  path: "/apps/company/:id",
  name: "Company",
  route: PrivateRoute,
  icon: "message-square",
  element: <PrivateRoute component={CompanyDetails} roles={["manager","admin"]} />,

};


const aggregatedSalaryDetailsRoutes = {
  path: "/apps/aggregatedSalary/:id",
  name: "aggregatedSalary",
  route: PrivateRoute,
  icon: "message-square",
  element: <PrivateRoute component={aggregatedSalaryDetails} roles={["manager","admin"]} />,

};
const ProfileRoutes = {
  path: "/apps/Profile",
  name: "Profile",
  route: PrivateRoute,
  icon: "message-square",
  element: <PrivateRoute component={Profile}  />,

};
const DepartmentsRoutes = {
  path: "/apps/branch/:id",
  name: "branch",
  route: PrivateRoute,
  icon: "message-square",
  element: <PrivateRoute component={BranchDetails} roles={["manager","admin","branch_manager"]} />,
};
const salariesAppRoutes = {
  path: "/apps/user/:id",
  name: "salaries",
  route: PrivateRoute,
  icon: "activity",
  element: <PrivateRoute component={SalariesApp} roles={["manager","admin","user","branch_manager"]} />,
};
const SalarySlipAppRoutes = {
  path: "/apps/salary/:id",
  name: "salaries",
  route: PrivateRoute,
  icon: "activity",
  element: <PrivateRoute component={SalarySlipApp} roles={["manager","admin","user","branch_manager"]} />,
};
const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  icon: "calendar",
  element: <PrivateRoute component={CalendarApp} roles={["admin"]} />,
  header: "Apps",
};

// const chatAppRoutes = {
//   path: "/apps/chat",
//   name: "Chat",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "message-square",
//   element: <ChatApp />,
// };

// const ecommerceAppRoutes = {
//   path: "/apps/ecommerce",
//   name: "eCommerce",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "shopping-cart",
//   children: [
//     {
//       path: "/apps/ecommerce/dashboard",
//       name: "Products",
//       element: <EcommerceDashboard />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/products",
//       name: "Products",
//       element: <EcommerceProducts />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/product-details",
//       name: "Product Details",
//       element: <ProductDetails />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/edit-product",
//       name: "Product Edit",
//       element: <ProductEdit />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/customers",
//       name: "Customers",
//       element: <Customers />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/orders",
//       name: "Orders",
//       element: <Orders />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/order/details",
//       name: "Order Details",
//       element: <OrderDetails />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/sellers",
//       name: "Sellers",
//       element: <Sellers />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/shopping-cart",
//       name: "Shopping Cart",
//       element: <Cart />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/ecommerce/checkout",
//       name: "Checkout",
//       element: <Checkout />,
//       route: PrivateRoute,
//     },
//   ],
// };



// const emailAppRoutes = {
//   path: "/apps/email",
//   name: "Email",
//   route: PrivateRoute,
//   icon: "mail",
//   children: [
//     {
//       path: "/apps/email/inbox",
//       name: "Inbox",
//       element: <Inbox />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/email/details",
//       name: "Email Details",
//       element: <EmailDetail />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/email/compose",
//       name: "Compose Email",
//       element: <EmailCompose />,
//       route: PrivateRoute,
//     },
//   ],
// };

// const socialAppRoutes = {
//   path: "/apps/social-feed",
//   name: "Social Feed",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "rss",
//   element: <SocialFeed />,
// };

// const CardAppRoutes = {
//   path: "/apps/Card",
//   name: "Card",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "activity",
//   element: <Card />,
// };

// const projectAppRoutes = {
//   path: "/apps/projects",
//   name: "Projects",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "uil-briefcase",

//   children: [
//     {
//       path: "/apps/projects/list",
//       name: "List",
//       element: <Projects />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/projects/:id/details",
//       name: "Detail",
//       element: <ProjectDetail />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/projects/create",
//       name: "Create Project",
//       element: <ProjectForm />,
//       route: PrivateRoute,
//     },
//   ],
// };

// const taskAppRoutes = {
//   path: "/apps/tasks",
//   name: "Tasks",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "clipboard",
//   children: [
//     {
//       path: "/apps/tasks/list",
//       name: "Task List",
//       element: <TaskList />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/tasks/details",
//       name: "Task List",
//       element: <TaskDetails />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/tasks/kanban",
//       name: "Kanban",
//       element: <Kanban />,
//       route: PrivateRoute,
//     },
//   ],
// };

// const contactsRoutes = {
//   path: "/apps/contacts",
//   name: "Contacts",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "book",
//   children: [
    // {
    //   path: "/apps/contacts/list",
    //   name: "Task List",
    //   element: <ContactsList />,
    //   route: PrivateRoute,
    // },
    // {
    //   path: "/apps/contacts/profile",
    //   name: "Profile",
    //   element: <ContactsProfile />,
    //   route: PrivateRoute,
    // },
//   ],
// };

// const ticketsRoutes = {
//   path: "/apps/tickets",
//   name: "Tickets",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "aperture",
//   children: [
//     {
//       path: "/apps/tickets/list",
//       name: "List",
//       element: <TicketsList />,
//       route: PrivateRoute,
//     },
//     {
//       path: "/apps/tickets/details",
//       name: "Details",
//       element: <TicketsDetails />,
//       route: PrivateRoute,
//     },
//   ],
// };

// const fileAppRoutes = {
//   path: "/apps/file-manager",
//   name: "File Manager",
//   route: PrivateRoute,
//   roles: ["Admin"],
//   icon: "folder-plus",
//   element: <FileManager />,
// };

const appRoutes = [
  calendarAppRoutes,
  companiesAppRoutes,
  aggregatedSalarysAppRoutes,
  SalariesByMonthAppRoutes,
  CompanyDetailsRoutes,
 
  aggregatedSalaryDetailsRoutes,
  ProfileRoutes,
  DepartmentsRoutes,
  salariesAppRoutes,
  SalarySlipAppRoutes,
  // ecommerceAppRoutes,

  // emailAppRoutes,
  // socialAppRoutes,
  // CardAppRoutes,
  // projectAppRoutes,
  // taskAppRoutes,
  // contactsRoutes,
//   ticketsRoutes,
//   fileAppRoutes,
 ];

// pages
const extrapagesRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      element: <PrivateRoute component={Starter} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/pages/timeline",
      name: "Timeline",
      element: <PrivateRoute component={Timeline} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/pages/sitemap",
      name: "Sitemap",
      element: <PrivateRoute component={Sitemap} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      element: <PrivateRoute component={Invoice} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/pages/faq",
      name: "FAQ",
      element: <PrivateRoute component={FAQ} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/pages/serach-results",
      name: "Search Results",
      element: <PrivateRoute component={SearchResults} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      element: <PrivateRoute component={Pricing} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/pages/gallery",
      name: "Gallery",
      element: <PrivateRoute component={Gallery} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      element: <PrivateRoute component={Error404Alt} roles={["admin"]} />,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes = {
  path: "/ui",
  name: "Components",
  icon: "pocket",
  header: "UI Elements",
  children: [
    {
      path: "/ui/base",
      name: "Base UI",
      children: [
        {
          path: "/ui/buttons",
          name: "Buttons",
          element: <PrivateRoute component={Buttons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/cards",
          name: "Cards",
          element: <PrivateRoute component={Cards} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/avatars",
          name: "Avatars",
          element: <PrivateRoute component={Avatars} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/portlets",
          name: "Portlets",
          element: <PrivateRoute component={Portlets} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/tabs-accordions",
          name: "Tabs & Accordions",
          element: <PrivateRoute component={TabsAccordions} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/progress",
          name: "Progress",
          element: <PrivateRoute component={Progress} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/modals",
          name: "Modals",
          element: <PrivateRoute component={Modals} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/notifications",
          name: "Notifications",
          element: <PrivateRoute component={Notifications} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/offcanvas",
          name: "Offcanvas",
          element: <PrivateRoute component={Offcanvases} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/placeholders",
          name: "Placeholders",
          element: <PrivateRoute component={Placeholders} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/spinners",
          name: "Spinners",
          element: <PrivateRoute component={Spinners} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/images",
          name: "Images",
          element: <PrivateRoute component={Images} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/carousel",
          name: "Carousel",
          element: <PrivateRoute component={Carousels} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/listgroups",
          name: "List Groups",
          element: <PrivateRoute component={ListGroups} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/embedvideo",
          name: "EmbedVideo",
          element: <PrivateRoute component={EmbedVideo} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/dropdowns",
          name: "Dropdowns",
          element: <PrivateRoute component={Dropdowns} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/ribbons",
          name: "Ribbons",
          element: <PrivateRoute component={Ribbons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/tooltips-popovers",
          name: "Tooltips & Popovers",
          element: <PrivateRoute component={TooltipsPopovers} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/typography",
          name: "Typography",
          element: <PrivateRoute component={Typography} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/grid",
          name: "Grid",
          element: <PrivateRoute component={Grid} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/general",
          name: "General UI",
          element: <PrivateRoute component={GeneralUI} roles={["admin"]} />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/extended",
      name: "Extended UI",
      children: [
        {
          path: "/extended-ui/nestable",
          name: "Nestable List",
          element: <PrivateRoute component={NestableList} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/dragdrop",
          name: "Drag and Drop",
          element: <PrivateRoute component={DragDrop} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/rangesliders",
          name: "Range Sliders",
          element: <PrivateRoute component={RangeSliders} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/animation",
          name: "Animation",
          element: <PrivateRoute component={Animation} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/sweet-alert",
          name: "Sweet Alert",
          element: <PrivateRoute component={SweetAlerts} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/tour",
          name: "Tour Page",
          element: <PrivateRoute component={TourPage} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/loading-buttons",
          name: "Loading Buttons",
          element: <PrivateRoute component={LoadingButtons} roles={["admin"]} />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/widgets",
      name: "Widgets",
      element: <PrivateRoute component={Widgets} roles={["admin"]} />,
      route: PrivateRoute,
    },
    {
      path: "/ui/icons",
      name: "Icons",
      children: [
        {
          path: "/ui/icons/two-tone",
          name: "Two Tone Icons",
          element: <PrivateRoute component={TwoToneIcons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/feather",
          name: "Feather Icons",
          element: <PrivateRoute component={FeatherIcons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/dripicons",
          name: "Dripicons",
          element: <PrivateRoute component={Dripicons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/mdi",
          name: "Material Design",
          element: <PrivateRoute component={MDIIcons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/font-awesome",
          name: "Font Awesome 5",
          element: <PrivateRoute component={FontAwesomeIcons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/themify",
          name: "Themify",
          element: <PrivateRoute component={ThemifyIcons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/simple-line",
          name: "Simple Line Icons",
          element: <PrivateRoute component={SimpleLineIcons} roles={["admin"]} />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/weather",
          name: "Weather Icons",
          element: <PrivateRoute component={WeatherIcons} roles={["admin"]} />,
          route: PrivateRoute,
        },
      ],
    },
    // {
    //   path: "/ui/forms",
    //   name: "Forms",
    //   children: [
    //     {
    //       path: "/ui/forms/basic",
    //       name: "Basic Elements",
    //       element: <BasicForms />,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: "/ui/forms/advanced",
    //       name: "Form Advanced",
    //       element: <FormAdvanced />,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: "/ui/forms/validation",
    //       name: "Form Validation",
    //       element: <FormValidation />,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: "/ui/forms/wizard",
    //       name: "Form Wizard",
    //       element: <FormWizard />,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: "/ui/forms/upload",
    //       name: "File Upload",
    //       element: <FileUpload />,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: "/ui/forms/editors",
    //       name: "Editors",
    //       element: <Editors />,
    //       route: PrivateRoute,
    //     },
    //   ],
    // },
    // {
    //   path: "/ui/tables",
    //   name: "Tables",
    //   children: [
    //     {
    //       path: "/ui/tables/basic",
    //       name: "Basic",
    //       element: <BasicTables />,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: "/ui/tables/advanced",
    //       name: "Advanced",
    //       element: <AdvancedTables />,
    //       route: PrivateRoute,
    //     },
    //   ],
    // },
    // {
    //   path: "/ui/charts",
    //   name: "Charts",
    //   children: [
    //     {
    //       path: "/ui/charts/apex",
    //       name: "Apex",
    //       element: <ApexChart />,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: "/ui/charts/chartjs",
    //       name: "Chartjs",
    //       element: <ChartJs />,
    //       route: PrivateRoute,
    //     },
    //   ],
    // },
    // {
    //   path: "/ui/maps",
    //   name: "Maps",
    //   children: [
    //     {
    //       path: "/ui/googlemaps",
    //       name: "Google Maps",
    //       element: <GoogleMaps />,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: "/ui/vectorMaps",
    //       name: "Google Maps",
    //       element: <VectorMaps />,
    //       route: PrivateRoute,
    //     },
    //   ],
    // },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
 
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  // {
  //   path: "/auth/register2",
  //   name: "Register2",
  //   element: <Register2 />,
  //   route: Route,
  // },
  // {
  //   path: "/auth/confirm2",
  //   name: "Confirm2",
  //   element: <Confirm2 />,
  //   route: Route,
  // },
  // {
  //   path: "/auth/forget-password2",
  //   name: "Forget Password2",
  //   element: <ForgetPassword2 />,
  //   route: Route,
  // },
  // {
  //   path: "/auth/signin-signup2",
  //   name: "SignIn-SignUp2",
  //   element: <SignInSignUp2 />,
  //   route: Route,
  // },
  // {
  //   path: "/auth/lock-screen2",
  //   name: "Lock Screen2",
  //   element: <LockScreen2 />,
  //   route: Route,
  // },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/landing", 
    name: "landing",
    element: <Landing />,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <PrivateRoute component={Maintenance} roles={["admin"]} />,

    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <PrivateRoute component={Error404} roles={["admin"]} />,
    route: Route,
  },
  {
    path: "/error-404-two",
    name: "Error - 404 Two",
    element: <PrivateRoute component={Error404Two} roles={["admin"]} />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <PrivateRoute component={Error500} roles={["admin"]} />,
    route: Route,
  },
  {
    path: "/error-500-two",
    name: "Error - 500 Two",
    element: <PrivateRoute component={Error500Two} roles={["admin"]} />,
    route: Route,
  },
  {
    path: "/upcoming",
    name: "Coming Soon",
    element: <PrivateRoute component={Upcoming} roles={["admin"]} />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routesp
const authProtectedRoutes = [
  // dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
const AppRoutes = () => (
  <Router>
  <Suspense fallback={<Loading />}>
    <Routes>
      {authProtectedFlattenRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.route === PrivateRoute ? (
              <PrivateRoute component={route.element as React.ComponentType<any>} roles={route.roles} />
            ) : (
              // Explicitly cast route.element to 'any' here
              <route.route element={route.element as any} />
            )
          }
        />
      ))}
      {publicProtectedFlattenRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element as any} />
      ))}
      <Route path="/unauthorized" element={<Unauthorized />} />
    </Routes>
  </Suspense>
</Router>
);

const Unauthorized = () => {
  return (
    <div>
      <h1>Unauthorized</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};

export default AppRoutes;
