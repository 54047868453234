import jwtDecode from "jwt-decode";
import axios from "axios";
import config from "../../config";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message;
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          message = "Invalid credentials";
          break;
        case 403:
          message = "Access Forbidden";
          window.location.href = "/access-denied";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default:
          message = error.response.data?.message || error.message || error;
      }
    }
    return Promise.reject(message);
  }
);

const AUTH_SESSION_KEY = "ubold_user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromSession = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? JSON.parse(user) : null;
};

class APICore {
  get = (url: string, params?: any) => {
    if (params) {
      const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join("&");
      return axios.get(`${url}?${queryString}`, { params });
    }
    return axios.get(url);
  };

  getFile = (url: string, params?: any) => {
    if (params) {
      const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join("&");
      return axios.get(`${url}?${queryString}`, { responseType: "blob" });
    }
    return axios.get(url, { responseType: "blob" });
  };

  getMultiple = (urls: string[], params?: any) => {
    const requests = urls.map((url) => {
      if (params) {
        const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join("&");
        return axios.get(`${url}?${queryString}`);
      }
      return axios.get(url);
    });
    return axios.all(requests);
  };

  create = (url: string, data: any) => axios.post(url, data);

  updatePatch = (url: string, data: any) => axios.patch(url, data);

  update = (url: string, data: any) => axios.put(url, data);

  delete = (url: string) => axios.delete(url);

  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.post(url, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return axios.patch(url, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();
    if (!user) {
      return false;
    }
    const decoded: any = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }
    return true;
  };

  setLoggedInUser = (session: any) => {
    if (session) {
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
      setAuthorization(session.token);
    } else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
      setAuthorization(null);
    }
  };

  getLoggedInUser = () => {
    return getUserFromSession();
  };

  setUserInSession = (modifiedUser: any) => {
    const userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, ...user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

// Initialisation : Vérifier si un utilisateur est déjà connecté et définir l'Authorization Header
const AUTH_TOKEN = getUserFromSession()?.token;
if (AUTH_TOKEN) {
  setAuthorization(AUTH_TOKEN);
}

export { APICore, setAuthorization };



// import jwtDecode from "jwt-decode";
// import axios from "axios";

// import config from "../../config";

// // content type
// axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.baseURL = config.API_URL;

// // intercepting to capture errors
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     let message;

//     if (error && error.response && error.response.status === 404) {
//       // window.location.href = '/not-found';
//     } else if (error && error.response && error.response.status === 403) {
//       window.location.href = "/access-denied";
//     } else {
//       switch (error.response.status) {
//         case 401:
//           message = "Invalid credentials";
//           break;
//         case 403:
//           message = "Access Forbidden";
//           break;
//         case 404:
//           message = "Sorry! the data you are looking for could not be found";
//           break;
//         default: {
//           message =
//             error.response && error.response.data
//               ? error.response.data["message"]
//               : error.message || error;
//         }
//       }
//       return Promise.reject(message);
//     }
//   }
// );

// const AUTH_SESSION_KEY = "ubold_user";

// /**
//  * Sets the default authorization
//  * @param {*} token
//  */
// const setAuthorization = (token: string | null) => {
//   if (token) axios.defaults.headers.common["Authorization"] = "JWT " + token;
//   else delete axios.defaults.headers.common["Authorization"];
// };

// const getUserFromCookie = () => {
//   const user = sessionStorage.getItem(AUTH_SESSION_KEY);
//   return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
// };
// class APICore {
//   /**
//    * Fetches data from given url
//    */
//   get = (url: string, params: any) => {
//     let response;
//     if (params) {
//       var queryString = params
//         ? Object.keys(params)
//             .map((key) => key + "=" + params[key])
//             .join("&")
//         : "";
//       response = axios.get(`${url}?${queryString}`, params);
//     } else {
//       response = axios.get(`${url}`, params);
//     }
//     return response;
//   };

//   getFile = (url: string, params: any) => {
//     let response;
//     if (params) {
//       var queryString = params
//         ? Object.keys(params)
//             .map((key) => key + "=" + params[key])
//             .join("&")
//         : "";
//       response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
//     } else {
//       response = axios.get(`${url}`, { responseType: "blob" });
//     }
//     return response;
//   };

//   getMultiple = (urls: string, params: any) => {
//     const reqs = [];
//     let queryString = "";
//     if (params) {
//       queryString = params
//         ? Object.keys(params)
//             .map((key) => key + "=" + params[key])
//             .join("&")
//         : "";
//     }

//     for (const url of urls) {
//       reqs.push(axios.get(`${url}?${queryString}`));
//     }
//     return axios.all(reqs);
//   };

//   /**
//    * post given data to url
//    */
//   create = (url: string, data: any) => {
//     return axios.post(url, data);
//   };

//   /**
//    * Updates patch data
//    */
//   updatePatch = (url: string, data: any) => {
//     return axios.patch(url, data);
//   };

//   /**
//    * Updates data
//    */
//   update = (url: string, data: any) => {
//     return axios.put(url, data);
//   };

//   /**
//    * Deletes data
//    */
//   delete = (url: string) => {
//     return axios.delete(url);
//   };

//   /**
//    * post given data to url with file
//    */
//   createWithFile = (url: string, data: any) => {
//     const formData = new FormData();
//     for (const k in data) {
//       formData.append(k, data[k]);
//     }

//     const config = {
//       headers: {
//         ...axios.defaults.headers,
//         "content-type": "multipart/form-data",
//       },
//     };
//     return axios.post(url, formData, config);
//   };

//   /**
//    * post given data to url with file
//    */
//   updateWithFile = (url: string, data: any) => {
//     const formData = new FormData();
//     for (const k in data) {
//       formData.append(k, data[k]);
//     }

//     const config = {
//       headers: {
//         ...axios.defaults.headers,
//         "content-type": "multipart/form-data",
//       },
//     };
//     return axios.patch(url, formData, config);
//   };

//   isUserAuthenticated = () => {
//     const user = this.getLoggedInUser();

//     if (!user) {
//       return false;
//     }
//     const decoded: any = jwtDecode(user.token);
//     const currentTime = Date.now() / 1000;
//     if (decoded.exp < currentTime) {
//       console.warn("access token expired");
//       return false;
//     } else {
//       return true;
//     }
//   };

//   setLoggedInUser = (session: any) => {
//     if (session)
//       sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
//     else {
//       sessionStorage.removeItem(AUTH_SESSION_KEY);
//     }
//   };
//   /**
//    * Returns the logged in user
//    */
//   getLoggedInUser = () => {
//     return getUserFromCookie();
//   };

//   setUserInSession = (modifiedUser: any) => {
//     let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
//     if (userInfo) {
//       const { token, user } = JSON.parse(userInfo);
//       this.setLoggedInUser({ token, ...user, ...modifiedUser });
//     }
//   };
// }

// /*
// Check if token available in session
// */
// let user = getUserFromCookie();
// if (user) {
//   const { token } = user;
//   if (token) {
//     setAuthorization(token);
//   }
// }

// export { APICore, setAuthorization };


// import jwtDecode from "jwt-decode";

// const BASE_URL = 'http://localhost:8000'; // Remplacez par l'URL de votre serveur backend

// // Clé pour le stockage de session utilisateur dans sessionStorage
// const AUTH_SESSION_KEY = "ubold_user";

// // Fonction pour définir le token JWT dans les headers d'Axios
// const setAuthorization = (token: string | null) => {
//   if (token) {
//     // Définir le token JWT dans le localStorage
//     localStorage.setItem('token', token);
//   } else {
//     // Supprimer le token JWT du localStorage si null
//     localStorage.removeItem('token');
//   }
// };

// // Fonction pour récupérer l'utilisateur depuis le sessionStorage
// const getUserFromCookie = () => {
//   const user = sessionStorage.getItem(AUTH_SESSION_KEY);
//   return user ? JSON.parse(user) : null;
// };

// class APICore {
//   // Méthode pour vérifier si l'utilisateur est authentifié
//   isUserAuthenticated = () => {
//     const user = this.getLoggedInUser();

//     if (!user) {
//       return false;
//     }

//     // Décoder le token JWT pour obtenir sa date d'expiration
//     const decoded: any = jwtDecode(user.token);
//     const currentTime = Date.now() / 1000;

//     // Vérifier si le token JWT a expiré
//     if (decoded.exp < currentTime) {
//       console.warn("Le token d'accès a expiré");
//       return false;
//     } else {
//       return true;
//     }
//   };

//   // Méthode pour enregistrer les informations de session de l'utilisateur
//   setLoggedInUser = (session: any) => {
//     if (session) {
//       sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
//       // Mettre à jour le token JWT dans le localStorage après l'enregistrement
//       setAuthorization(session.token);
//     } else {
//       sessionStorage.removeItem(AUTH_SESSION_KEY);
//       // Supprimer le token JWT du localStorage après la suppression de la session
//       setAuthorization(null);
//     }
//   };

//   // Méthode pour récupérer l'utilisateur connecté à partir du sessionStorage
//   getLoggedInUser = () => {
//     return getUserFromCookie();
//   };

//   // Méthodes pour effectuer différentes opérations CRUD avec fetch

//   // Méthode pour effectuer une requête GET
//   get = async (url: string, params?: any) => {
//     let fullUrl = `${BASE_URL}${url}`;
//     if (params) {
//       const queryString = Object.keys(params)
//         .map((key) => `${key}=${params[key]}`)
//         .join("&");
//       fullUrl = `${fullUrl}?${queryString}`;
//     }
//     const response = await fetch(fullUrl);
//     return response.json();
//   };

//   // Méthode pour télécharger un fichier via GET
//  getFile = async (url: string, params?: any) => {
//   let fullUrl = `${BASE_URL}${url}`;
//   if (params) {
//     const queryString = Object.keys(params)
//       .map((key) => `${key}=${params[key]}`)
//       .join("&");
//     fullUrl = `${fullUrl}?${queryString}`;
//   }
//   const response = await fetch(fullUrl);
//   const blob = await response.blob();
//   return blob;
// };

//   // Méthode pour effectuer plusieurs requêtes GET simultanées
//   getMultiple = async (urls: string[], params?: any) => {
//     const requests = urls.map(async (url) => {
//       let fullUrl = `${BASE_URL}${url}`;
//       if (params) {
//         const queryString = Object.keys(params)
//           .map((key) => `${key}=${params[key]}`)
//           .join("&");
//         fullUrl = `${fullUrl}?${queryString}`;
//       }
//       const response = await fetch(fullUrl);
//       return response.json();
//     });
//     return Promise.all(requests);
//   };

//   // Méthode pour effectuer une requête POST
//   create = async (url: string, data: any) => {
//     const fullUrl = `${BASE_URL}${url}`;
//     const response = await fetch(fullUrl, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//       },
//       body: JSON.stringify(data),
//     });
//     return response.json();
//   };

//   // Méthode pour effectuer une requête PATCH
//   updatePatch = async (url: string, data: any) => {
//     const fullUrl = `${BASE_URL}${url}`;
//     const response = await fetch(fullUrl, {
//       method: 'PATCH',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//       },
//       body: JSON.stringify(data),
//     });
//     return response.json();
//   };

//   // Méthode pour effectuer une requête PUT
//   update = async (url: string, data: any) => {
//     const fullUrl = `${BASE_URL}${url}`;
//     const response = await fetch(fullUrl, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//       },
//       body: JSON.stringify(data),
//     });
//     return response.json();
//   };

//   // Méthode pour effectuer une requête DELETE
//   delete = async (url: string) => {
//     const fullUrl = `${BASE_URL}${url}`;
//     const response = await fetch(fullUrl, {
//       method: 'DELETE',
//       headers: {
//         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//       },
//     });
//     return response.json();
//   };

//   // Méthode pour effectuer une requête POST avec fichier
//   createWithFile = async (url: string, data: any) => {
//     const fullUrl = `${BASE_URL}${url}`;
//     const formData = new FormData();
//     for (const key in data) {
//       formData.append(key, data[key]);
//     }
//     const response = await fetch(fullUrl, {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//       },
//       body: formData,
//     });
//     return response.json();
//   };

//   // Méthode pour effectuer une requête PATCH avec fichier
//   updateWithFile = async (url: string, data: any) => {
//     const fullUrl = `${BASE_URL}${url}`;
//     const formData = new FormData();
//     for (const key in data) {
//       formData.append(key, data[key]);
//     }
//     const response = await fetch(fullUrl, {
//       method: 'PATCH',
//       headers: {
//         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//       },
//       body: formData,
//     });
//     return response.json();
//   };
// }

// // Initialisation : Vérifier si un utilisateur est déjà connecté et définir l'Authorization Header
// const AUTH_TOKEN = localStorage.getItem('token');
// if (AUTH_TOKEN) {
//   setAuthorization(AUTH_TOKEN);
// }

// // Exporter APICore et setAuthorization pour les utiliser dans d'autres parties de l'application
// export { APICore, setAuthorization };
