// import React from "react";
// import { Route, Navigate, RouteProps } from "react-router-dom";

// import { APICore } from "../helpers/api/apiCore";

// /**
//  * Private Route forces the authorization before the route can be accessed
//  * @param {*} param0
//  * @returns
//  */
// const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
//   const api = new APICore();

//   return (
//     <Route
//       {...rest}
//       render={(props: RouteProps) => {
//         if (api.isUserAuthenticated() === false) {
//           // not logged in so redirect to login page with the return url
//           return (
//             <Navigate
//               // state={from: props['path']}
//               to={{
//                 pathname: "/auth/register",
//                 // state: { from: props['path'] },
//               }}
//             />
//           );
//         }

//         const loggedInUser = api.getLoggedInUser();

//         // check if route is restricted by role
//         if (roles && roles.indexOf(loggedInUser.role) === -1) {
//           // role not authorised so redirect to login page
//           return <Navigate to={{ pathname: "/" }} />;
//         }
//         // authorised so return component
//         return <Component {...props} />;
//       }}
//     />
//   );
// };

// export default PrivateRoute;







// import React from "react";
// import { Route, Navigate, RouteProps } from "react-router-dom";



// /**
//  * Private Route forces the authorization before the route can be accessed
//  * @param {*} param0
//  * @returns
//  */
// const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {

//   const isAuthenticated = () => {
//     const token = localStorage.getItem('token');
//     // Implement your logic to check token validity
//     return !!token; // Example: return true if token exists and valid
    
//   };
 
 
//   return (
//     <Route
//       {...rest}
//       render={(props: RouteProps) => {
//         if (!isAuthenticated()) {
//           // not logged in so redirect to login page with the return url
//           return (
//             <Navigate
//               // state={from: props['path']}
//               to={{
//                 pathname: "/auth/login",
//                 // state: { from: props['path'] },
//               }}
//             />
//           );
//         }

     

//         // Autorisé, retourner le composant
//         return <Component {...props} />;
//       }}
//     />
//   );
// };

// export default PrivateRoute;



import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from './auth';

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const ComponentToRender = (props: any) => {
    if (!isAuthenticated()) {
      return <Navigate to="/auth/login" replace />;
    }

    // const userRole = localStorage.getItem('userRole');
    const storedUserString = localStorage.getItem('user');
    const storedUser = storedUserString ? JSON.parse(storedUserString) : null;
  
    // Accéder aux données spécifiques
    const userRole = storedUser?.role_name;

    if (roles && roles.indexOf(userRole) === -1) {
      return <Navigate to="/no-auth" replace />;
    }

    return <Component {...props} />;
  };

  return <ComponentToRender {...rest} />;
};

export default PrivateRoute;
