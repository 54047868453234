// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { APICore } from '../helpers/api/apiCore';

// const Root = () => {
//   const api = new APICore();

//   const getRootUrl = () => {
//     if (api.isUserAuthenticated()) {
//       return '/dashboard-1';
//     }
//     return '/landing';
//   };

//   const url = getRootUrl();

//   return <Navigate to={url} />;
// };

// export default Root;


import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from './auth';

const Root = () => {
  useEffect(() => {

    // Perform any necessary side effects here
  }, []); // Empty dependency array to run effect only once

  const url = isAuthenticated() ? '/apps/profile' : '/landing';

  return <Navigate to={url} />;
};

export default Root;